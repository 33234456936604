import React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import KitForm from "../components/kitdigital-form"
import imgKitMain from '../images/kit-digital/kitdigital.svg';
import imgSolWeb from '../images/kit-digital/kit-web.svg';
import imgSolCiberseguridad from '../images/kit-digital/kit-ciberseguridad.svg';
import imgSolRRHH from '../images/kit-digital/kit-rrhh.svg';
import imgLogos from '../images/kit-digital/logos.png';


const KitDigitalPage = () => {


	return (
		<Layout className="page-kitdigital">
			<SEO
				title="Programa Kit Digital | DMS"
				description="En DMS te ayudamos a implementar las soluciones clave para la gestión y digitalización de tu negocio. Apuesta por una transición segura y eficaz"
			/>

			<section className="bloque bloque-featured">
				<div className="container">
					<div className="row">
						<div className="col-md-6 main-header">
							<h1>DMS, tu agente digitalizador para el cambio</h1>
							<p>Las soluciones clave para la digitalización de tu negocio y la automatización de procesos con ayuda profesional y especializada.</p>

							<p>Optimiza tu protocolo de trabajo y saca el máximo partido a las subvenciones recibidas.</p>

							<Link className="btn btn-accent" title="Saber más sobre Kit Digital" to="#bloqueForm">Saber más</Link>
						</div>

						<div className="col-md-6">
							<img src={imgKitMain} alt="Soporte informático que te dejará tranquilo" />
						</div>
					</div>
				</div>
			</section>



			<section className="bloque-introkit">
				<div className="container">
					<h2 className="title">¿Qué es el programa Kit Digital?</h2>
					<p>Kit Digital es el programa de ayudas lanzado por la Unión Europea que tiene como objetivo proporcionar y conseguir la digitalización de los autónomos y Pymes del ecosistema internacional. Unas subvenciones extraidas y canalizadas a partir de los fondos Next Generation EU dotadas de 3.067 millones de euros y enmarcadas en España en el Plan de Recuperación, Transformación y Resiliencia, la agenda España Digital 2025 y el Plan de Digitalización de Pymes 2021 - 2025. </p>
					<p>Con las subvenciones recibidas en tu negocio y a través de los agentes digitalizadores como DMS y Plain, conseguirás llevar a tu empresa a otro nivel, sumar soluciones y herramientas digitales que te ayuden a optimizar procesos y generar mayores ventas y en definitiva, empezar - avanzar y/o completar el proceso de transformación digital de tu negocio. </p>
					<p>Para acceder al programa solo tendrás que cumplir los requisitos detallados <a href="https://www.boe.es/boe/dias/2021/12/30/pdfs/BOE-A-2021-21873.pdf" target="_blank" className="link">la página 13 del BOE</a>, inscribirte en el programa, elegir qué área de tu empresa necesita de las ayudas de agentes digitalizadores y ejecutar, juntos, las acciones personalizadas que te propondremos. </p>
					<p>Si eres autónomo y/o tienes una empresa de hasta 49 empleados, es tu momento perfecto para dar un salto cualitativo en la gestión de tu negocio. Hazte ahora con las ayudas que propone el programa Kit Digital y conviértete en uno de los destinatarios de las ayudas de los fondos europeos para la transformación digital. <a href="https://sede.red.gob.es/es/procedimientos/convocatoria-de-ayudas-destinadas-la-digitalizacion-de-empresas-del-segmento-i-entre" target="_blank" className="link">Convocatoria para pymes hasta 49 empleados</a> (a partir del 15 de marzo)</p>


					<a href="https://plain.ninja/blog/kit-digital-subvenciones-para-la-digitalizacion-de-las-pymes/" target="_blank" className="link link-solo" title="Leer más sobre Kit Digital">Leer más sobre Kit Digital</a>

					<img className="banner-ministerio" src={imgLogos} alt="Soporte informático que te dejará tranquilo" />
				</div>
			</section>


			<section className="bloque-soluciones">
				<div className="container">
					<h2 className="title">Soluciones digitales de DMS incluidas en el programa Kit Digital</h2>
					<p>Identifica las necesidades de tu empresa y completa el ciclo de digitalización en todas las áreas y equipos de tu negocio.</p>
					<p>Lánzate al mercado con una presencia única en internet, aumenta la seguridad de tu site y gestiona como un pro el horario de tu equipo aumentando su productividad con nuestras soluciones digitales. </p>
				</div>
			</section>


			<section className="bloque-solucion b1">
				<div className="container">

					<div className="row">
						<div className="bloque-main col-md-6">
							<h4 className="subtitulo-solucion">Categoría Gestión de Procesos</h4>
							<h3 className="title">Soluciones de control horario, gestión de turnos y ausencias</h3>
							<p>Digitaliza y automatiza los procesos internos de tu empresa para conseguir aumentar la productividad y eficacia de tu equipo y tus componentes. Gestión de turnos de trabajo, horarios, RRHH, ausencias y vacaciones desde un único lugar.</p>
							<ul>
								<li>Digitalización y/o automatización de procesos y flujos de trabajo</li>
								<li>Gestión de Recursos Humanos de la empresa</li>
								<li>Control horario con informes oficiales</li>
								<li>Planificación automática de turnos de trabajo</li>
								<li>Gestión del tiempo unificada</li>
								<li>Solución escalable a las necesidades de la empresa y del equipo</li>
								<li>Gestión de vacaciones y ausencias desde la app</li>
								<li>Soporte telefónico y asistencia email - chat</li>
							</ul>

							<Link className="btn btn-accent" title="Saber más sobre Kit Digital" to="#bloqueForm">Saber más</Link>

							<h5 className="titulo-precios">Precios Pack Anual</h5>
							<ul class="kit-precios">
								<li><strong>Segmento III 1 usuario, de 0 a 2 empleados  &gt;</strong><br />
									<span class="line-through">250€ + IVA/año</span> 0€ precio con subvención</li>
								<li><strong>Segmento II 3 usuarios, de 3 a 9 empleados &gt;</strong><br />
									<span class="line-through">500€ + IVA/año</span> 0€ precio con subvención</li>
								<li><strong>Segmento I 10 usuarios, de 10 a 49 empleados &gt;</strong><br />
									<span class="line-through">1700€ + IVA/año</span> 0€ precio con subvención</li>
							</ul>
						</div>
						<div className="bloque-image col-md-6">
							<img src={imgSolRRHH} alt="Categoría gestión de procesos" />
						</div>
					</div>
				</div>
			</section>


			<section className="bloque-solucion b2">
				<div className="container">

					<div className="row">
						<div className="bloque-main col-md-6">
							<h4 className="subtitulo-solucion">Categoría Sitio web y presencia en internet</h4>
							<h3 className="title">Soluciones y posicionamiento DMS</h3>
							<p>Aumenta la presencia de tu empresa en Internet captando la atención de tus clientes con un sitio web atractivo, moderno y funcional. La clave para mostrar tus productos y servicios a nivel internacional.</p>
							<ul>
								<li>Servicio de Hosting y alojamiento web</li>
								<li>Diseño y desarrollo de páginas web según estructura requerida</li>
								<li>Webs responsive y autogestionable</li>
								<li>Sites webs accesibles con niveles AA de pautas WCAG-2.1</li>
								<li>Posicionamiento básico en Internet</li>
								<li>Optimización SEO básico y avanzado en buscadores</li>
							</ul>

							<Link className="btn btn-accent" title="Saber más sobre Kit Digital" to="#bloqueForm">Saber más</Link>

							<h5 className="titulo-precios">Precios Pack Anual</h5>
							<p>web corporativa + dominio + hosting + posicionamiento básico</p>
							<ul class="kit-precios">
								<li><strong>Segmento III 1 usuario, de 0 a 2 empleados  &gt;</strong><br />
									<span class="line-through">1.800€ + IVA</span> 0€ precio con subvención</li>
								<li><strong>Segmento II 3 usuarios, de 3 a 9 empleados &gt;</strong><br />
									<span class="line-through">1.800€ + IVA</span> 0€ precio con subvención</li>
								<li><strong>Segmento I 10 usuarios, de 10 a 49 empleados &gt;</strong><br />
									<span class="line-through">1.800€ + IVA</span> 0€ precio con subvención</li>
							</ul>
						</div>
						<div className="bloque-image col-md-6">
							<img src={imgSolWeb} alt="Categoría web" />
						</div>
					</div>
				</div>
			</section>

			<section className="bloque-solucion b3">
				<div className="container">

					<div className="row">
						<div className="bloque-image col-md-6 order-1 order-md-0">
							<img src={imgSolCiberseguridad} alt="Categoría ciberseguridad" />
						</div>
						<div className="bloque-main col-md-6 order-0 order-md-1">
							<h4 className="subtitulo-solucion">Categoría Ciberseguridad</h4>
							<h3 className="title">Soluciones de ciberseguridad</h3>
							<p>Tus dispositivos, tu documentación y todos tus sites a salvo con soluciones ad-hoc de ciberseguridad para tu negocio. Todo lo que necesitas para que tu equipo trabaje con absoluta tranquilidad y tus clientes confíen en tus servicios.</p>
							<ul>
								<li>Soluciones Antimalware de análisis de memoria interna y dispositivos externos</li>
								<li>Soluciones Antispyware para detectar y evitar el malware espía</li>
								<li>Herramientas para correo seguro con antispam y antiphishing</li>
								<li>Soporte para navegación segura y control de contenidos</li>
								<li>Antiadware que evite anuncios maliciosos</li>
							</ul>

							<Link className="btn btn-accent" title="Saber más sobre Kit Digital" to="#bloqueForm">Saber más</Link>

							<h5 className="titulo-precios">Precios Pack Anual</h5>
							<ul class="kit-precios">
								<li><strong>Segmento III 1 usuario, de 0 a 2 empleados  &gt;</strong><br />
									<span class="line-through">125€/dispositivo + IVA/año</span> 0€ precio con subvención</li>
								<li><strong>Segmento II 3 usuarios, de 3 a 9 empleados &gt;</strong><br />
									<span class="line-through">125€/dispositivo + IVA/año</span> 0€ precio con subvención</li>
								<li><strong>Segmento I 10 usuarios, de 10 a 49 empleados &gt;</strong><br />
									<span class="line-through">125€/dispositivo + IVA/año</span> 0€ precio con subvención</li>
							</ul>
						</div>
					</div>


				</div>
			</section>


			<section id="bloqueForm" className="bloque-page-contacto bloque-final">
				<div className="contacto-content">
					<div className="container">
						<div className="row">
							<div className="col-md-6 bloque-main">
								<h2 className="title">Es el momento de la digitalización: la forma más fácil de evolucionar tu negocio
								</h2>
								<p>Es el momento de dejarte ayudar por profesionales. Súmate al proyecto KitDigital y solicita las ayudas disponibles para tu proyecto como autónomo y/o tu pyme. El equipo de DMS está preparado para analizar tu situación y ofrecerte la solución que mejor se adapte a tus necesidades de digitalización y evolución empresarial. </p>
								<p>Que nada frene, en el siglo XXI, el salto a lo digital de tu negocio. El límite ahora lo pones tú. ¿Te sumas?</p>

								<div className="video-wrapper">
									<iframe title="YouTube video player" src="https://www.youtube.com/embed/fI_Y5W25hPs" allowfullscreen="allowfullscreen" width="560" height="315" frameborder="0"></iframe>
								</div>
							</div>

							<div className="col-md-6">
								<KitForm />
							</div>
						</div>
					</div>
				</div>
			</section>

		</Layout>
	);
};

export default KitDigitalPage
